// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

var jQeury = require('jquery')
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery

require("./includes/listings")
require("./includes/datatables")
// require("datatables.net-bs4")
// require("datatables.net-buttons-bs4");
// require("datatables.net-buttons-bs4/css/buttons.bootstrap4.min");
// require("datatables.net-buttons-bs4/js/buttons.bootstrap4.min.js");
// require("datatables.net-buttons/js/dataTables.buttons.js");
// require('datatables.net-buttons/js/buttons.html5.min.js');
// require('datatables.net-buttons/js/buttons.flash.min.js');
// require('datatables.net-buttons/js/buttons.print.min.js');


window.pdfMake = require('pdfmake/build/pdfmake.min');
var vfs = require('pdfmake/build/vfs_fonts');
window.pdfMake.vfs = vfs.pdfMake.vfs;

var jQeury = require('jquery')
global.$ = global.jQuery = jQuery
window.$ = window.jQuery = jQuery


// import "bootstrap"
// import "../stylesheets/carousel"
// import "../stylesheets/style"
// import "../stylesheets/_dashboard"

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import "trix"
import "@rails/actiontext"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

document.addEventListener("turbolinks:load", () => {
  // $('[data-toggle="tooltip"]').tooltip()
  // $('[data-toggle="popover"]').popover()
})


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "controllers"
