$(function() {
  $("#show-tel").on("click", function() {
    var $this = $(this)
    $this.find("span").text( $this.data("mobile") );
  });

  $('#contact-modal').on('show.bs.modal', function (event) {
    var button = $(event.relatedTarget)
    var modal = $(this)
  })

  $("#send-message-to-agent").on("click", function() {
    var agent_id = $("#agent_id").val(),
    listing_id = $("#listing_id").val(),
    first_name = $("#message-first-name").val(),
    last_name = $("#message-last-name").val(),
    email = $("#message-email").val(),
    mobile = $("#message-mobile").val(),
    message = $("#message-text").val();

    $.ajax({
      url: "/agent/message",
      method: "POST",
      dataType: "json",
      data: {
        agent_id: agent_id,
        listing_id: listing_id,
        firstname: first_name,
        lastname: last_name,
        email: email,
        mobile: mobile,
        message: message
      },
      success: function(paramsdata) {
        $('#contact-modal form').remove();
        $('#send-message-to-agent').remove();
        $('#contact-modal .modal-body').html("<p>You message has been sent successfully. Our agent will contact you within next 24 hours</p>")      }
    });
  });

});
